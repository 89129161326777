import React, { useState, useEffect, lazy, Suspense } from "react";

import * as Sentry from "@sentry/react";

import { Switch, Route, Redirect } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider, Query } from "react-apollo";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/fi";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Settings as DateTimeSettings } from "luxon";
import { useTranslation } from "react-i18next";
import { InMemoryCache } from "apollo-cache-inmemory";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import gql from "graphql-tag";

import "./App.scss";
import "./shared/styles/global.scss";

import {
  Loader,
  ErrorMessage,
  MainLayout,
  Sidebar,
  SidebarButton,
  SidebarHeading,
  UserAgent,
  NoMatch,
  ComingSoon,
  en,
  fi,
  tougoCookies,
  retry,
} from "./shared";

import Logo from "./components/Logo/Logo";

import {
  GET_CHILDREN_FIRST_NAMES,
  GET_UNREAD_ANNOUNCEMENTS_COUNT,
  GET_UNREAD_COUNT,
  GET_UNSEEN_PHOTOS_COUNT,
  IS_LOGGED_IN,
} from "./queries";
import { signOut } from "./helpers/sessionHelpers";

import NavigationBar from "./components/NavigationBar/NavigationBar";
import TabBar from "./components/TabBar/TabBar";
import { TabBarButton } from "./components/TabBarButton/TabBarButton";

import HomeIcon from "mdi-react/HomeIcon";
import ContactPhoneIcon from "mdi-react/ContactPhoneIcon";
import CalendarIcon from "mdi-react/CalendarIcon";

import CalendarStarIcon from "mdi-react/CalendarStarIcon";

import CalendarTextIcon from "mdi-react/CalendarTextIcon";

import MessageIcon from "mdi-react/MessageIcon";
import ImageMultipleIcon from "mdi-react/ImageMultipleIcon";
import SettingsIcon from "mdi-react/SettingsIcon";
import QuestionMarkCircleIcon from "mdi-react/QuestionMarkCircleIcon";
import AccountChildCircleIcon from "mdi-react/AccountChildCircleIcon";
import FileDocumentOutlineIcon from "mdi-react/FileDocumentOutlineIcon";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "INTERFACE",
          name: "Messager",
          possibleTypes: [
            {
              name: "DaycareCenter",
            },
            {
              name: "SimpleMessager",
            },
            {
              name: "Worker",
            },
            {
              name: "DaycareGroup",
            },
            {
              name: "Child",
            },
            {
              name: "Guardian",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "Author",
          possibleTypes: [
            {
              name: "Worker",
            },
            {
              name: "Guardian",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "Media",
          possibleTypes: [
            {
              name: "Photo",
            },
            {
              name: "Video",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "Channel",
          possibleTypes: [
            {
              name: "MessageThread",
            },
            {
              name: "MessageThreadGroup",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "MessagingSearchRow",
          possibleTypes: [
            {
              name: "MessageThread",
            },
            {
              name: "Message",
            },
            {
              name: "Announcement",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "MessagingEntity",
          possibleTypes: [
            {
              name: "MessageThread",
            },
            {
              name: "MessageThreadGroup",
            },
            {
              name: "Announcement",
            },
          ],
        },
        {
          kind: "INTERFACE",
          name: "MessagingHeading",
          possibleTypes: [
            {
              name: "MessageThread",
            },
            {
              name: "Announcement",
            },
          ],
        },
      ],
    },
  },
});

// Screens
const Dashboard = lazy(() => retry(() => import("./screens/Dashboard")));
const Contacts = lazy(() => retry(() => import("./screens/Contacts")));
const Absence = lazy(() => retry(() => import("./screens/Absence")));
const Events = lazy(() => retry(() => import("./screens/Events")));
const Gallery = lazy(() => retry(() => import("./screens/Gallery")));
const GalleryPhoto = lazy(() => retry(() => import("./screens/GalleryPhoto")));
const GalleryPhotoModal = lazy(() => retry(() => import("./screens/GalleryPhotoModal")));
const Fullscreen = lazy(() => retry(() => import("./layouts/Fullscreen/Fullscreen")));
const Messages = lazy(() => retry(() => import("./screens/Messages")));
const Profile = lazy(() => retry(() => import("./screens/Profile")));
const Reservations = lazy(() => retry(() => import("./screens/Reservations")));
const Settings = lazy(() => retry(() => import("./screens/Settings")));
const Login = lazy(() => retry(() => import("./screens/Login")));
const Support = lazy(() => retry(() => import("./screens/Support")));
const LoginLayout = lazy(() => retry(() => import("./layouts/LoginLayout/LoginLayout")));
const Onboarding = lazy(() => retry(() => import("./screens/Onboarding")));
const VasuReservation = lazy(() => retry(() => import("./screens/VasuReservation")));
const VasuDocument = lazy(() => retry(() => import("./screens/VasuDocument")));

export const NavigationContext = React.createContext();

const cache = new InMemoryCache({ fragmentMatcher });

const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
  }
`;

const resolvers = {
  Query: {
    isLoggedIn: () => !!tougoCookies.get("sessionToken") && tougoCookies.get("acceptedTerms"),
  },
};

const client = new ApolloClient({
  uri: process.env.REACT_APP_TOUGO_API,
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      Sentry.setContext("graphql_errors", graphQLErrors);
      if (graphQLErrors.find((error) => error.extensions?.code === "NOT_AUTHENTICATED")) {
        signOutSession();
      }
    }
  },
  request: (operation) => {
    Sentry.setContext("graphql_operation", { name: operation.operationName, query: operation.query });
    const currentLanguage = (tougoCookies.get("language") || "fi-FI").split("-")[0];
    const sessionId = tougoCookies.get("sessionId") || "";
    const sessionToken = tougoCookies.get("sessionToken") || "";
    if (sessionId && sessionId.length > 0 && sessionToken && sessionToken.length > 0) {
      operation.setContext({
        headers: {
          "Guardian-Session-Id": sessionId,
          "Guardian-Session-Token": sessionToken,
          "Accept-Language": currentLanguage,
        },
      });
    }
  },
  cache,
  typeDefs,
  resolvers,
});

const signOutSession = () => {
  signOut(client);
};

client.cache.writeData({
  data: {
    isLoggedIn: !!tougoCookies.get("sessionToken") && tougoCookies.get("acceptedTerms"),
  },
});

const initialLanguage = tougoCookies.get("language") || "fi-FI";
i18n.use(initReactI18next).init({
  fallbackLng: initialLanguage,
  resources: {
    en: { translation: en },
    fi: { translation: fi },
  },
  debug: process.env.NODE_ENV === "development",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

i18n.changeLanguage(initialLanguage);
dayjs.extend(LocalizedFormat);
dayjs.locale(initialLanguage.split("-")[0]);
if (window.Beacon) {
  window.Beacon("config", {
    hideAvatars: true,
    labels: i18n.t("HelpScout", { returnObjects: true }),
  });
}
DateTimeSettings.defaultLocale = initialLanguage.split("-")[0];
DateTimeSettings.defaultZoneName = "Europe/Helsinki";

i18n.on("languageChanged", (language) => {
  dayjs.locale(language.split("-")[0]);
  window.testLocale = dayjs.locale(language.split("-")[0]);
  if (window.Beacon) {
    window.Beacon("config", {
      hideAvatars: true,
      labels: i18n.t("HelpScout", { returnObjects: true }),
    });
  }
  DateTimeSettings.defaultLocale = language.split("-")[0];
});

// HACK: hack to make sure /shared uses the same instance of dayjs as /customer or /staff
window.localizedDayjs = dayjs;

const RouteWithLayout = ({
  component: Component,
  layout: Layout,
  setBackUrl,
  setTitle,
  backUrl,
  title,
  isPublic,
  updatePhotoCount,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isPublic) {
          return (
            <Query query={IS_LOGGED_IN}>
              {({ data }) =>
                data && data.isLoggedIn ? (
                  <NavigationContext.Provider value={{ title, backUrl, setTitle, setBackUrl }}>
                    <Layout
                      aside={(sideNavOpen) => (
                        <Sidebar
                          open={sideNavOpen}
                          logo={<Logo />}
                          primaryNavigation={
                            <>
                              <SidebarHeading text={t("CustomerApp.SidebarHeading.title")} />
                              <PrimaryNavigationLinks component={SidebarButton} updatePhotoCount={updatePhotoCount} />
                            </>
                          }
                          secondaryNavigation={
                            <div>
                              <SidebarHeading text={t("CustomerApp.SidebarHeading.children")} />
                              <SecondaryNavigationLinks component={SidebarButton} />
                            </div>
                          }
                          bottomNavigation={<BottomNavigationLinks component={SidebarButton} />}
                        />
                      )}
                      top={(toggleSideNav) => (
                        <UserAgent web>
                          <NavigationBar toggleSideNav={toggleSideNav} title={title} backUrl={backUrl} />
                        </UserAgent>
                      )}
                      bottom={
                        <UserAgent web>
                          <TabBar>
                            <PrimaryNavigationLinks component={TabBarButton} bottom />
                          </TabBar>
                        </UserAgent>
                      }
                    >
                      <Suspense fallback={<Loader text={t("CustomerApp.loader")} centered large />}>
                        <Component {...props} setBackUrl={setBackUrl} setTitle={setTitle} />
                      </Suspense>
                    </Layout>
                  </NavigationContext.Provider>
                ) : (
                  <Redirect
                    to={{
                      pathname: "/login",
                      state: { from: props.location },
                    }}
                  />
                )
              }
            </Query>
          );
        } else {
          return (
            <Layout>
              <Suspense fallback={<Loader text={t("CustomerApp.loader")} centered large />}>
                <Component {...props} />
              </Suspense>
            </Layout>
          );
        }
      }}
    />
  );
};

const RouteWithoutLayout = ({
  component: Component,
  layout: Layout,
  setBackUrl,
  setTitle,
  backUrl,
  title,
  t,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      return (
        <NavigationContext.Provider value={{ title, backUrl, setTitle, setBackUrl }}>
          <Suspense fallback={<Loader text={t("CustomerApp.loader")} centered large />}>
            <Component {...props} setBackUrl={setBackUrl} setTitle={setTitle} />
          </Suspense>
        </NavigationContext.Provider>
      );
    }}
  />
);

function App(props) {
  const [backUrl, setBackUrl] = useState("");
  const [title, setTitle] = useState("");
  const [previousLocation, setPreviousLocation] = useState(props.location);
  const { location } = props;
  const { t } = useTranslation();

  const otherProps = {
    setTitle: setTitle,
    setBackUrl: setBackUrl,
    backUrl: backUrl,
    title: title,
  };

  useEffect(() => {
    if (window.TouGoAndroid && window.TouGoAndroid.setLanguage) {
      window.TouGoAndroid.setLanguage(tougoCookies.get("language") || "fi-FI");
    } else if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers.setLanguage &&
      window.webkit.messageHandlers.setLanguage.postMessage
    ) {
      window.webkit.messageHandlers.setLanguage.postMessage({ language: tougoCookies.get("language") || "fi-FI" });
    }
  }, []);

  useEffect(() => {
    let { location } = props;
    if (props.history.action !== "POP" && (!location.state || !location.state.modal)) {
      setPreviousLocation(props.location);
    }
  }, [props]);

  useEffect(() => {
    document.title = (title ? title + " | " : "") + "TouGo";
  }, [title]);

  const isModal = !!(location.state && location.state.modal && previousLocation !== location);

  return (
    <ApolloProvider client={client}>
      {/* FYI: Suspense is here to catch any Layouts that need to be lazy-loaded */}
      <Suspense fallback={<Loader text={t("CustomerApp.loader")} centered large />}>
        <Switch location={isModal ? previousLocation : location}>
          <RouteWithLayout
            isPublic
            exact
            path={"/login"}
            layout={LoginLayout}
            component={Login}
            {...otherProps}
            {...props}
          />
          <RouteWithLayout exact path={"/"} layout={MainLayout} component={Dashboard} {...otherProps} {...props} />
          <RouteWithLayout path={"/absence"} layout={MainLayout} component={Absence} {...otherProps} {...props} />
          <RouteWithLayout path={"/support"} layout={MainLayout} component={Support} {...otherProps} {...props} />
          <RouteWithLayout
            path={"/gallery"}
            layout={MainLayout}
            component={Gallery}
            updatePhotoCount={true}
            {...otherProps}
            {...props}
          />
          <RouteWithLayout
            path={"/photo/:id"}
            layout={Fullscreen}
            component={GalleryPhoto}
            {...otherProps}
            {...props}
          />
          <RouteWithLayout path={"/messages"} layout={MainLayout} component={Messages} {...otherProps} {...props} />
          <RouteWithLayout
            exact
            path={"/profile/:id"}
            layout={MainLayout}
            component={Profile}
            {...otherProps}
            {...props}
          />
          <Route path="/reservations" exact>
            <Redirect to={`/reservations/${dayjs().format("YYYY-MM")}`} />
          </Route>
          <RouteWithLayout
            path={"/reservations/:date"}
            layout={MainLayout}
            component={Reservations}
            {...otherProps}
            {...props}
          />
          <RouteWithLayout
            exact
            path={"/settings"}
            layout={MainLayout}
            component={Settings}
            {...otherProps}
            {...props}
          />

          <RouteWithLayout exact path={"/events"} layout={MainLayout} component={Events} {...otherProps} {...props} />
          <RouteWithLayout
            exact
            path={"/events/:id"}
            layout={MainLayout}
            component={Events}
            {...otherProps}
            {...props}
          />

          <RouteWithLayout
            exact
            path={"/contacts"}
            layout={MainLayout}
            component={Contacts}
            {...otherProps}
            {...props}
          />
          <RouteWithLayout
            exact
            path={"/vasu/discussions"}
            layout={MainLayout}
            component={VasuReservation}
            {...otherProps}
            {...props}
          />

          <RouteWithLayout
            exact
            path={"/vasu/documents"}
            layout={MainLayout}
            component={VasuDocument}
            {...otherProps}
            {...props}
          />
          <RouteWithLayout
            exact
            path={"/vasu/documents/:id"}
            layout={MainLayout}
            component={VasuDocument}
            {...otherProps}
            {...props}
          />
          <RouteWithLayout
            exact
            path={"/vasu/documents/:id/document/:document_id"}
            layout={MainLayout}
            component={VasuDocument}
            {...otherProps}
            {...props}
          />

          <RouteWithLayout path="/onboarding" layout={MainLayout} component={Onboarding} {...otherProps} {...props} />
          <Route component={NoMatch} />
        </Switch>

        {isModal ? (
          <RouteWithoutLayout t={t} path="/photo/:id" component={GalleryPhotoModal} {...otherProps} {...props} />
        ) : null}
      </Suspense>
    </ApolloProvider>
  );
}

function PrimaryNavigationLinks({ component, updatePhotoCount, bottom }) {
  const Component = component;
  const { t } = useTranslation();
  const [shouldRefetch, setShouldRefetch] = useState(false);

  useEffect(() => {
    setShouldRefetch(updatePhotoCount);
  }, [updatePhotoCount]);

  return (
    <>
      <Component to="/" label={t("CustomerApp.PrimaryNavigationLinks.front_page")} icon={<HomeIcon />} exact />
      <Query query={GET_UNREAD_COUNT} pollInterval={1000 * 60 * 5}>
        {({ data: { unreadMessageThreadsCount } = {}, loading, error }) => {
          let count = unreadMessageThreadsCount ? unreadMessageThreadsCount : 0;
          return (
            <Query query={GET_UNREAD_ANNOUNCEMENTS_COUNT} pollInterval={1000 * 60 * 5}>
              {({ data: { unreadAnnouncementsCount } = {}, loading, error }) => {
                if (unreadAnnouncementsCount) count += unreadAnnouncementsCount;

                if (window.TouGoAndroid && window.TouGoAndroid.unreadMessageThreadsCount) {
                  window.TouGoAndroid.unreadMessageThreadsCount(count);
                } else if (
                  window.webkit &&
                  window.webkit.messageHandlers &&
                  window.webkit.messageHandlers.unreadMessageThreadsCount &&
                  window.webkit.messageHandlers.unreadMessageThreadsCount.postMessage
                ) {
                  window.webkit.messageHandlers.unreadMessageThreadsCount.postMessage(count);
                }

                return (
                  <Component
                    to="/messages"
                    label={t("CustomerApp.PrimaryNavigationLinks.messages")}
                    badge={count && count !== 0 ? count : null}
                    icon={<MessageIcon />}
                  />
                );
              }}
            </Query>
          );
        }}
      </Query>
      <Component
        to="/reservations"
        label={t("CustomerApp.PrimaryNavigationLinks.reservations")}
        icon={<CalendarIcon />}
      />
      <Component to="/events" label={t("Eventcalendar.Events")} icon={<CalendarStarIcon />} />

      <Query query={GET_UNSEEN_PHOTOS_COUNT} pollInterval={1000 * 60 * 5}>
        {({ data: { currentGuardian: { unseenPhotosCount } = {} } = {}, loading, error, refetch }) => {
          const count = unseenPhotosCount ? unseenPhotosCount : 0;

          if (window.TouGoAndroid && window.TouGoAndroid.unseenPhotosCount) {
            window.TouGoAndroid.unseenPhotosCount(count);
          } else if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.unseenPhotosCount &&
            window.webkit.messageHandlers.unseenPhotosCount.postMessage
          ) {
            window.webkit.messageHandlers.unseenPhotosCount.postMessage(count);
          }

          if (shouldRefetch) {
            refetch();
            setShouldRefetch(false);
          }

          return (
            <Component
              to="/gallery"
              label={t("CustomerApp.PrimaryNavigationLinks.image_gallery")}
              badge={count && count !== 0 ? count : null}
              icon={<ImageMultipleIcon />}
            />
          );
        }}
      </Query>
      {!bottom && (
        <>
          <Component to="/contacts" label={t("General.Contacts")} icon={<ContactPhoneIcon />} />
          <Component to="/vasu/discussions" label={t("Vasu.discussions")} icon={<CalendarTextIcon />} />
          <Component to="/vasu/documents" label={t("Vasu.vasu_plans_title")} icon={<FileDocumentOutlineIcon />} />
        </>
      )}
    </>
  );
}

function SecondaryNavigationLinks(props) {
  const [navChildren, setNavChildren] = useState(null);
  const Component = props.component;
  const { t } = useTranslation();
  return (
    <Query query={GET_CHILDREN_FIRST_NAMES}>
      {({ data: { children } = {}, loading, error }) => {
        if (loading) {
          return <Loader text={t("CustomerApp.loader")} className="padding-24 padding-x margin-24 margin-top" inline />;
        }
        if (error) {
          return (
            <ErrorMessage error={error} className="padding-24 padding-x margin-24 margin-top">
              {t("CustomerApp.load_error")}
            </ErrorMessage>
          );
        }

        if (children) {
          setNavChildren(children);
        }

        if (!navChildren) {
          return null;
        }

        const childrenForNativeApps = navChildren.map((child) => ({
          id: child.id,
          profilePath: `/profile/${child.id}`,
          firstName: child.firstName,
        }));

        if (window.TouGoAndroid && window.TouGoAndroid.onChildren) {
          window.TouGoAndroid.onChildren(JSON.stringify(childrenForNativeApps));
        } else if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.children &&
          window.webkit.messageHandlers.children.postMessage
        ) {
          window.webkit.messageHandlers.children.postMessage({
            children: childrenForNativeApps,
          });
        }
        return navChildren.map((child) => (
          <Component
            key={child.id}
            to={{ pathname: `/profile/${child.id}`, state: { backUrl: "" } }}
            label={child.firstName}
            icon={<AccountChildCircleIcon />}
          />
        ));
      }}
    </Query>
  );
}

function BottomNavigationLinks(props) {
  const Component = props.component;
  const { t } = useTranslation();
  return (
    <>
      <Component
        to="/support"
        label={t("CustomerApp.BottomNavigationLinks.support")}
        icon={<QuestionMarkCircleIcon />}
      />
      <Component to="/settings" label={t("CustomerApp.BottomNavigationLinks.settings")} icon={<SettingsIcon />} />
    </>
  );
}

export default App;
