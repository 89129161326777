import React from "react";

import * as Sentry from "@sentry/react";

import ErrorIcon from "mdi-react/ErrorIcon";
import { ReactComponent as LogoSymbol } from "../../assets/images/logo-symbol-blue.svg";
import { tougoCookies, sentryConfig } from "../../shared";

import styles from "./ErrorBoundary.module.scss";

import { useEffect } from "react";

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const sentryEnvironment = process.REACT_APP_SENTRY_ENVIRONMENT || process.env.REACT_APP_CONTEXT || process.env.NODE_ENV;
const sentryRelease = process.env.REACT_APP_COMMIT_REF;

Sentry.init({
  ...sentryConfig,
  dsn: sentryDsn,
  environment: sentryEnvironment,
  release: sentryRelease,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    // Sentry.reactRouterV6BrowserTracingIntegration({
    //   useEffect,
    //   useLocation,
    //   useNavigationType,
    //   createRoutesFromChildren,
    //   matchRoutes,
    // }),
    // Sentry.replayIntegration(),
  ],
});

const ErrorBoundary = ({ children }) => {
  useEffect(() => {
    Sentry.setUser({ id: tougoCookies.get("sessionUserId") });
  }, []);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <div className={styles.error}>
          <div className={styles.inner}>
            <ErrorIcon className={styles.icon} />
            {error.message && error.message.includes("Loading chunk") ? (
              <div className={styles.networkError}>
                <div className={styles.networkErrorInner}>
                  <LogoSymbol className={styles.networkErrorIcon} />
                  Ei verkkoyhteyttä
                </div>
              </div>
            ) : (
              <div className={styles.error}>
                <div className={styles.inner}>
                  <ErrorIcon className={styles.icon} />
                  Tapahtui virhe / There was an error
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
